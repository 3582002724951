import {useNavigate} from "react-router-dom";
import {useSelector} from "react-redux";
import React from "react";

import CosmeticSuccessModalTemplate from "components/molecules/CosmeticModalTemplate";

const DivinePurchaseSuccessfulModal = ({show, setWhichModalOpen, quantity}) => {
    const {name, image_url, rarity} = useSelector(state => state.divineShop.currentCosmetic.data)
    const navigate = useNavigate()

    return (
        <CosmeticSuccessModalTemplate
            show={show}
            name={name}
            rarity={rarity}
            image={image_url}
            isShowClose={true}
            quantity={quantity}
            title="Purchase successful!"
            closeClick={() => setWhichModalOpen("")}
            buttonOne={{text: "Back to shop", callBack: () => navigate("/shop?tab=divine")}}
        />
    )
}

export default DivinePurchaseSuccessfulModal