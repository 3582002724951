import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux";

import {formatNumberToEuropeanStyle, voolahPackageDetails} from "utils";
import {userGetVoolahBalance} from "store/AuthV2/AuthV2Slice";
import useVoolahShop from "./useVoolahShop";
import {useModalsContext} from "layouts";
import {useAuthorization, useGMTEvents} from "hooks";

import CosmeticViewTemplate from "components/molecules/CosmeticViewTemplate";
import VoolahSteps from "./modals/VoolahSteps";
import {Button, Icon} from "components/atoms";

import "./style.scss"

const VoolahShop = ({changeStep, widgetIsLoad}) => {
    const {signIn} = useSelector(state => state.authV2)
    const {paymentUIvoolah} = useSelector(state => state.voolahShop)

    const {voolahPurchaseComplete} = useGMTEvents()
    const dispatch = useDispatch()
    const {setCurrentModal} = useModalsContext()
    const {isAuth} = useAuthorization()

    const {
        searchParamsChecking,
        setXPayStationWidgetSettings,
        openVoolahOrderModal,
        XPayWidgetClose,
        XPayEventProcess,
        searchParams,
        setSearchParams,
        currentPackageIndex
    } = useVoolahShop()

    useEffect(() => {
        setCurrentModal({status: ""})
        searchParamsChecking()
        // eslint-disable-next-line
    }, [signIn.userData])

    useEffect(() => {
        if (paymentUIvoolah.success && paymentUIvoolah.token) {
            setCurrentModal({status: "order"})
        }
        // eslint-disable-next-line
    }, [paymentUIvoolah.success])

    useEffect(() => {
        if (widgetIsLoad) {
            setXPayStationWidgetSettings()
        }
        // eslint-disable-next-line
    }, [widgetIsLoad])

    useEffect(() => {
        if (XPayEventProcess.status === "done") {
            setTimeout(() => {
                setCurrentModal({status: "success"})
                dispatch(userGetVoolahBalance())
            }, 10)
            voolahPurchaseComplete()
        }
        // eslint-disable-next-line
    }, [XPayWidgetClose])

    const chooseVoolah = (index) => {
        searchParams.set("voolah", index)
        setSearchParams(searchParams)
    }

    return (
        <section className="shop-voolah">
            <CosmeticViewTemplate
                classname="shop-voolah_tabs_container"
                title={<>{formatNumberToEuropeanStyle(voolahPackageDetails[currentPackageIndex].value)} <Icon
                    name="voolahLogo"/></>}
                img={voolahPackageDetails[currentPackageIndex].src.big}
            >
                <div className="shop-voolah_tabs_container_choice">
                    <div className="shop-voolah_tabs_container_choice_items">
                        <span className="block-title">Select amount</span>
                        <div className="shop-voolah_tabs_container_choice_items_img">
                            {voolahPackageDetails.map((elm, index) =>
                                <div
                                    key={elm.id}
                                    onClick={() => chooseVoolah(index)}
                                    className={+currentPackageIndex === elm.id ? "active" : ""}
                                >
                                    <img alt={elm.alt} src={elm.src.small}/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="shop-voolah_tabs_container_price">
                        <span className="block-title">Current Price</span>
                        <div>${voolahPackageDetails[currentPackageIndex].price}</div>
                    </div>
                    <Button
                        onClick={() => isAuth ? openVoolahOrderModal() : setCurrentModal({status: "login"})}
                    >
                        Buy now
                    </Button>
                    <div className="shop-voolah_tabs_container_description">
                        <span className="block-title">Description</span>
                        <p>Voolah package for&nbsp;
                            {formatNumberToEuropeanStyle(voolahPackageDetails[currentPackageIndex].value)}&nbsp;Voolah.
                            Voolah is the in-game currency that’s spendable in KOMPETE. With it, you can buy the
                            Ticket, cosmetic items, and more.</p>
                    </div>
                </div>
            </CosmeticViewTemplate>
            <VoolahSteps
                changeStep={changeStep}
                currentPackageIndex={currentPackageIndex}
            />
        </section>
    )
}

export default VoolahShop