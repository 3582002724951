export const useLocalStorage = () => {
    const removeToken = () => localStorage.removeItem("accessToken")
    const removeSyncDate = () => localStorage.removeItem("sync_date")
    const removeLoginType = () => localStorage.removeItem("login")
    const setSignInNetwork = (network) => localStorage.setItem("sign_in_method", network)
    const setSignUpNetwork = (network) => localStorage.setItem("sign_up_method", network)
    const removeSignInNetwork = () => localStorage.removeItem("sign_in_method")
    const removeSignUpNetwork = () => localStorage.removeItem("sign_up_method")
    const isSignInMethodAvailable = () => localStorage.getItem("sign_in_method")
    const isSignUpMethodAvailable = () => localStorage.getItem("sign_up_method")

    return {
        removeToken,
        removeSyncDate,
        removeLoginType,
        setSignInNetwork,
        setSignUpNetwork,
        removeSignInNetwork,
        removeSignUpNetwork,
        isSignInMethodAvailable,
        isSignUpMethodAvailable
    }
}