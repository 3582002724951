import {formatEther, formatUnits} from "ethers/lib/utils";
import {createApi} from "@reduxjs/toolkit/query/react";

import {addressReduction, checkingTypeTime, timePastCalculation, TRANSACTION_TYPE, ENVIRONMENTS} from "utils";
import createFetchBaseSeverQuery from "config/createFetchBaseServerQuery";
import createGraphFetchBaseQuery from "config/createGraphFetchBaseQuery";
import {GLOBAL} from "../endpoint";

export const globalGraphApi = createApi({
    reducerPath: "global/graph/api",
    baseQuery: createGraphFetchBaseQuery({baseUrl: ENVIRONMENTS.ALCHEMY_GRAPHQL_SERVER}),
    endpoints: (builder) => ({
        getTransactions: builder.query({
            query: (data) => ({
                url: data.url,
                query: data.query,
                body: data.data
            }),
            transformResponse: async (response, _, arg) => {
                const {transferSingles} = response

                const convertedData = await Promise.all(transferSingles.map(async (transaction) => {

                    const response = await fetch(transaction.metadata, {
                        method: "GET",
                        headers: {
                            'Access-Control-Allow-Origin': "*"
                        }
                    })

                    if (response.status === 200) {
                        const metadata = await response.json()

                        transaction.name = metadata.name
                        transaction.image = metadata.image
                        transaction.token_id = transaction.KompeteGameAsset_id

                    } else {
                        transaction.name = "KOMPETE"
                        transaction.image = "https://mygamerstoragecontainer.blob.core.windows.net/nfs-image-storage/logo_image.png"
                    }

                    if (transaction.transaction.ordersMatched) {
                        const orderPrice = transaction.transaction.ordersMatched.price

                        switch (transaction.process) {
                            case TRANSACTION_TYPE.MINT:
                                transaction.price = Number(formatEther(orderPrice))
                                break
                            case TRANSACTION_TYPE.SOLD:
                                transaction.price = Number(formatUnits(orderPrice, arg.decimals))
                                break
                            default :
                                transaction.price = 0
                        }
                    } else {
                        transaction.price = 0
                    }

                    delete transaction.metadata
                    delete transaction.transaction
                    delete transaction.KompeteGameAsset_id

                    return transaction
                }))

                convertedData.forEach((transaction) => {
                    const formattedTime = checkingTypeTime(timePastCalculation(Number(transaction.blockTimestamp * 1000)))

                    transaction.blockTimestamp = `${formattedTime.value} ${formattedTime.name + (formattedTime.value > 1 ? "s" : "")} ago`
                    transaction.transactionHash = addressReduction(transaction.transactionHash, 5)

                    switch (transaction.process) {
                        case "Sold":
                            transaction.currency = transaction.price ? "kompete" : "eth"
                            if (!transaction.price) transaction.price = "-"
                            break
                        case "Transfer":
                            transaction.price = ""
                            break
                        case "Mint":
                            transaction.currency = "eth"
                            break
                        default:
                            transaction.price = ""
                    }
                })

                return convertedData
            },
            keepUnusedDataFor: 10,
            transformErrorResponse: () => {
                return []
            },
        }),
    })
})

export const globalMarketServerApi = createApi({
    reducerPath: "global/market/server/api",
    baseQuery: createFetchBaseSeverQuery({baseUrl: ENVIRONMENTS.MARKET_URL}),
    endpoints: (builder) => ({
        getRecentlyViewedItems: builder.query({
            query: (data) => ({
                url: GLOBAL.RECENTLY_VIEWeD_ITEMS,
                method: "POST",
                body: data,
            }),
            keepUnusedDataFor: 1,
            transformResponse: (response) => response.data
        }),
        getListedInventories: builder.query({
            query: (data) => ({
                url: GLOBAL.LISTED_ORDERS,
                body: data,
                method: "POST",
            }),
            keepUnusedDataFor: 1,
            transformResponse: (response) => response.data,
        }),
        getInventoryPriceHistory: builder.query({
            query: (data) => ({
                url: GLOBAL.PRICE_HISTORY,
                body: data,
                method: "POST",
            }),
            keepUnusedDataFor: 10,
            transformResponse: (response) => response.data,
        }),
        syncInventoryPriceHistory: builder.mutation({
            query: (data) => ({
                url: GLOBAL.SYNC_PRICE_HISTORY,
                body: data,
                method: "POST",
            })
        })
    })
})

export const {useGetTransactionsQuery} = globalGraphApi

export const {
    useGetListedInventoriesQuery,
    useGetRecentlyViewedItemsQuery,
    useGetInventoryPriceHistoryQuery,
    useSyncInventoryPriceHistoryMutation
} = globalMarketServerApi