import React, {memo, useEffect, useState} from 'react';
import {useSelector} from "react-redux";

import {
    getNftsTransactionsQuery,
    getNftTransactionsByAddressQuery,
    getNftTransactionsByTokenIdQuery
} from "utils/constant/query";
import {LIMIT_DEFAULT_DATA, STANDARD_LIMIT_OPTIONS, TABLE_HEADER, TRANSACTION_HISTORY_SECTION} from "utils";
import {useGetTransactionsQuery} from "store/Global/global.api";
import {useConnectWallet} from "hooks";
import {web3Utils} from "web3/utils";
import config from "web3/config"

import {TableContainer} from "components/molecules";

import "./style.scss"

const TransactionHistorySection = ({data, transactionHistorySection}) => {
    const {currentChain} = useConnectWallet()
    const {userData} = useSelector(state => state.authV2.signIn)

    const [requestState, setRequestState] = useState({skip: 0, first: 12})
    const [limitValue, setLimitValue] = useState(LIMIT_DEFAULT_DATA)
    const [decimals, setDecimals] = useState(10)
    const [graphqlAPI, setGraphqlAPI] = useState(null)
    const [query, setQuery] = useState(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => findGraphqlAPIAndQuery(), [data, currentChain])

    const {data: history, isLoading, isFetching} = useGetTransactionsQuery({
        decimals,
        url: graphqlAPI,
        query: query,
        data: requestState
    }, {
        skip: !query && !graphqlAPI && !requestState
    })

    const {data: nextData, isLoading: isLoadingNext} = useGetTransactionsQuery({
        decimals,
        url: graphqlAPI,
        query: query,
        data: {...requestState, skip: requestState.first + requestState.skip, first: 1}
    }, {
        skip: !query && !graphqlAPI && !requestState
    })

    const updateRequestState = (newState) => setRequestState({...requestState, ...newState});

    const findGraphqlAPIAndQuery = async () => {
        if (data || transactionHistorySection !== TRANSACTION_HISTORY_SECTION.INVENTORY_ACTIVITY) {
            const {paymentToken} = await web3Utils.getConfiguration(0, data ? data.chain_id : currentChain.chainId)
            const decimals = await paymentToken?.decimals() ?? 10

            switch (transactionHistorySection) {
                case TRANSACTION_HISTORY_SECTION.USER_ACTIVITY:
                    setGraphqlAPI(currentChain.graphqlAPI)
                    setQuery(getNftTransactionsByAddressQuery)
                    setRequestState({skip: 0, first: 12, address: userData.wallet})
                    break
                case TRANSACTION_HISTORY_SECTION.INVENTORY_ACTIVITY:
                    const supportedChains = config.chains[config.networkEnvironment]
                    setGraphqlAPI(supportedChains.find(chain => chain.chainId === data.chain_id).graphqlAPI)
                    setQuery(getNftTransactionsByTokenIdQuery)
                    setRequestState({skip: 0, first: 12, tokenId: data.token_id})
                    break
                case TRANSACTION_HISTORY_SECTION.BAZAAR_ACTIVITY:
                    setGraphqlAPI(currentChain.graphqlAPI)
                    setQuery(getNftsTransactionsQuery)
                    setRequestState({skip: 0, first: 36})
                    setLimitValue(STANDARD_LIMIT_OPTIONS[2])
                    break
                default:
                    setQuery(null)
                    setGraphqlAPI(null)
                    setRequestState(null)
            }

            setDecimals(decimals)
        }
    }

    const limitValueHandler = (event) => {
        const {value} = event.target.value;
        setLimitValue(value)
        updateRequestState({skip: 0, first: value.value});
    }

    const pageChangeHandler = (arrow) => {
        const {skip, first} = requestState;
        const newSkip = arrow === "next" ? skip + first : skip - first;
        updateRequestState({skip: newSkip});
    }

    return (
        <TableContainer
            skip={requestState.skip}
            limitValue={limitValue}
            tableHead={TABLE_HEADER}
            isQueryPagination={true}
            isDisableNext={!nextData?.length}
            tableBody={history ? history : []}
            callBackArrow={(arrow) => pageChangeHandler(arrow)}
            callBackLimit={(event) => limitValueHandler(event)}
            isLoading={isLoading | isFetching | isLoadingNext | !history}
        />
    )
}

export default memo(TransactionHistorySection)