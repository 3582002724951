import React from "react";
import {useNavigate} from "react-router-dom"

import ProgressiveImage from "components/atoms/ProgressiveImage";
import Title from "components/atoms/TitleTemplate";
import Button from "components/atoms/Button";

import {png} from "assets/png";
import "./style.scss"

const EconomyBuySell = () => {
    const navigate = useNavigate()

    return (
        <section className="economy_buy-sell container">
            <Title underline={true}><span>Buy & Sell cosmetics with others</span> using kompete token</Title>
            <div className="economy_buy-sell_content">
                <div className="economy_buy-sell_content_img-block">
                    <ProgressiveImage
                        alt="token"
                        imgSrc={png.KompeteTokenStackedPng}
                        previewSrc={png.KompeteTokenStackedWebp}
                    />
                </div>
                <div className="economy_buy-sell_content_info">
                    <h3>TRADE YOUR COSMETICS FREELY.</h3>
                    <p>Use KOMPETE Token to buy and sell cosmetics with other players on the Bazaar. Purchase exclusive
                        items that are no longer available in the shop or able to be earned in game. Sell the cosmetics
                        you've collected along the way. When your items are sold, you can cash out!</p>
                    <Button
                        view="secondary"
                        onClick={() => navigate("/buy-kompete-token")}
                    >
                        Get kompete token
                    </Button>
                </div>
            </div>
        </section>
    )
}
export default EconomyBuySell