import {userGetVoolahBalance} from "../AuthV2/AuthV2Slice";
import {createFetch} from "config/createFetch";

const getCurrentLimitedCosmetics = async (thunkAPI) => {
    return createFetch("TWO", "v1/item/limited", "POST")
        .then(result => result.data)
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

const getCurrentCosmeticBySku = async (data, thunkAPI) => {
    return createFetch("TWO", "v1/item/get", "POST", {}, data)
        .then(result => result.data)
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

const payForInventoryWithVoolah = async (data, thunkAPI) => {
    const {quantity, sku, currency} = data
    const projectId = process.env.REACT_APP_XSOLLA_KOMPETE_PROJECT_ID
    const jwt = thunkAPI.getState().authV2.signIn.userData.accessToken

    const requestGroup = [];

    for (let i = 0; i < quantity; i++) {
        const request = fetch(`https://store.xsolla.com/api/v2/project/${projectId}/payment/item/${sku + "-web2"}/virtual/${currency}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${jwt}`
            }
        });
        requestGroup.push(request);
    }

    return Promise.allSettled(requestGroup)
        .then((result) => {
            const fulfilledResponses = result
                .filter((result) => result.status === "fulfilled")
                .map((result) => result.value)
            return Promise.all(fulfilledResponses.map((response) => response.json()))
        })
        .then((orders) => {
            const data = {
                sku,
                quantity: orders.length,
                order_id: orders.map(order => order.order_id)
            }

            createFetch("TWO", "v1/item/limited/buyWeb2", "POST", {authorization: true}, data)
            thunkAPI.dispatch(userGetVoolahBalance())

            return {quantitySuccess: orders.length}
        })
        .catch(error => thunkAPI.rejectWithValue(error?.message || error))
}

export const limitedShopService = {
    payForInventoryWithVoolah,
    getCurrentLimitedCosmetics,
    getCurrentCosmeticBySku,
}