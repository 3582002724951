import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import classNames from "classnames";

import {getPosts} from "store/NewsPosts/postSlice";

import LoadSpinner from "components/atoms/LoadSpinner";
import ArticleCart from "components/atoms/ArticleCart";
import Title from "components/atoms/TitleTemplate";
import {Icon} from "components/atoms";

import "./style.scss"

const NewsContent = () => {
    const dispatch = useDispatch()
    const {posts, loading} = useSelector(state => state.posts)
    const [newsCount, setNewsCount] = useState(0)

    const buttonStyle = classNames("news_content-container_news_more-btn", {hidden: posts?.length < 9})

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => dispatch(getPosts({first: 9, skip: newsCount})), [newsCount])

    const changePage = (type) => {
        if (type === "left" && newsCount) {
            setNewsCount(prevState => prevState - 9)
        }

        if (type === "right" && posts.length === 9) {
            setNewsCount(prevState => prevState + 9)
        }
    }

    return (
        <section className="news_content-container container">
            <Title underline={true}>Here's <span>what's happening</span></Title>
            {loading && !posts
                ? <div className="news_content-container_loading"><LoadSpinner/></div>
                : <div className="news_content-container_news">
                    <div>
                        {posts?.map((post) =>
                            <ArticleCart
                                key={post.id}
                                date={post.date}
                                text={post.title}
                                img={post.image.url}
                                postId={post.slug}
                            />
                        )}
                    </div>
                    <div className={buttonStyle}>
                        <button onClick={() => changePage("left")}><Icon name="leftSymbol"/></button>
                        <button onClick={() => changePage("right")}><Icon name="rightSymbol"/></button>
                    </div>
                </div>}

        </section>
    )
}

export default NewsContent