import React, {memo, useEffect, useState} from "react";
import {formatUnits} from "ethers/lib/utils";
import {useNavigate} from "react-router-dom";

import {useAuthorization, useConnectedWallet, useConnectWallet, useUtils} from "hooks";
import {ERROR, errorHandler, formatNumberToEuropeanStyle, STEPS_STATUS} from "utils";
import {bazaarService} from "store/Bazaar/bazaarService";
import {useModalsContext} from "layouts";
import {useBazaar} from "../useBazaar";
import {web3Utils} from "web3/utils";

import BazaarCurrentCosmeticInfo from "./BazaarCurrentCosmeticInfo";
import {CountDownWithContainer} from "components/molecules";

const BazaarCurrentCosmeticUserBlock = ({inventory, data, isSuccess, isLoading}) => {
    const {isConnected, isConnectedAddressCorrect, address, getKompeteBalance} = useConnectedWallet()
    const {openWalletModal, disconnectWallet} = useConnectWallet()
    const {getChainDataById, addChain, switchChain} = useUtils()
    const {changeStep, setCurrentModal} = useModalsContext()
    const {isAuth} = useAuthorization()

    const navigate = useNavigate()

    const {createOrder, sendTransaction} = useBazaar()

    const [isStartCreatingOrder, setIsStartCreatingOrder] = useState(false)
    const [isBalanceCorrect, setIsBalanceCorrect] = useState(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => startCreatingOrder(), [isStartCreatingOrder])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => balanceChecking(), [address]);

    const balanceChecking = async () => {
        if (address) {
            const {paymentToken} = await web3Utils.getConfiguration(0, Number(data.chain))
            const decimals = await paymentToken.decimals()
            const balance = formatUnits(await paymentToken.balanceOf(address), decimals)
            const inventoryPrice = data.price

            setIsBalanceCorrect(Number(balance) > inventoryPrice)
        }
    }

    const navigateTo = () => navigate("/token")

    const buttonDetails = {
        onClick: () => isAuth
            ? isConnected
                ? isBalanceCorrect ? buyInventory() : navigateTo()
                : openWalletModal()
            : changeStep("step1"),
        text: isAuth ? isConnected ? isBalanceCorrect ? "buy now" : "buy more kompete" : "connect wallet" : "Sign in",
        isDisabled: (isAuth && isConnected) ? isBalanceCorrect === null : false
    }

    const buyInventory = async () => {
        setCurrentModal({status: STEPS_STATUS.PROCESS})

        if (!isConnectedAddressCorrect) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_CONNECTED_MATCH_INVALID})
            disconnectWallet()

            return
        }

        try {
            const {chain} = data
            const {chainIdHex, chainId} = getChainDataById(Number(chain))
            const {status, error} = await switchChain(chainIdHex)

            if (error) errorHandler(ERROR.CLEAN)

            if (!status && error.code === 4902) {
                const {error} = await addChain(chainId)

                if (error) errorHandler(ERROR.CLEAN)
            }

            setIsStartCreatingOrder(true)
        } catch (error) {
            setCurrentModal({status: STEPS_STATUS.REJECT, message: ERROR.WALLET_FAILED})
        }
    }

    const startCreatingOrder = async () => {

        if (isStartCreatingOrder) {
            setIsStartCreatingOrder(false)

            const {price, chain, inventory, order, signature} = data
            const chainId = Number(chain)

            try {
                const {buyOrder, buySignature} = await createOrder(price, inventory.token_id, chainId)

                const isAvailable = await bazaarService.checkOrderAvailability({order_id: data.id})

                if (!isAvailable) errorHandler(ERROR.NO_COSMETIC)

                const result = await sendTransaction(order, signature, buyOrder, buySignature, chainId, data.id)

                if (!result.status) errorHandler(ERROR.TRANSACTION_FAILED)

                await getKompeteBalance(address, chainId)

                setCurrentModal({
                    status: STEPS_STATUS.ORDER_PURCHASE_SUCCESS,
                    data: {name: inventory.name, rarity: inventory.rarity.name, image_url: inventory.image_url},
                })
            } catch (error) {
                const isErrorMessageLong = error.message.length > 200

                setCurrentModal({
                    status: STEPS_STATUS.REJECT,
                    message: isErrorMessageLong ? ERROR.NO_COSMETIC : error.message
                })
            }
        }
    }

    return (
        <React.Fragment>
            <div className="bazaar-current-cosmetic-section_content_countdown">
                <CountDownWithContainer
                    day={true}
                    success={isSuccess}
                    loading={isLoading}
                    text="This item expires in:"
                    date={data?.expiration_date}
                />
            </div>
            <BazaarCurrentCosmeticInfo
                isLoading={isLoading}
                isSuccess={isSuccess}
                buttonDetails={buttonDetails}
                description={inventory?.description}
                price={formatNumberToEuropeanStyle(data?.price)}
            />
        </React.Fragment>
    )
}

export default memo(BazaarCurrentCosmeticUserBlock);