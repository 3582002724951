import {useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import React from "react";

import {limitedReset} from "store/LimitedShop/limitedTimeShopSlice";
import {setRefetchInventory} from "store/Locker/lockerSlice";

import {CosmeticModalTemplate} from "components/molecules";
import {useModalsContext} from "layouts";

const LimitedTimeCurrentCosmeticSuccessful = ({show, setWhichModalOpen}) => {
    const navigate = useNavigate()
    const {data} = useSelector(state => state.limitedShop.cosmeticPayment)
    const {currentModal} = useModalsContext()
    const dispatch = useDispatch()

    const returnToInventory = () => {
        setWhichModalOpen("")
        dispatch(setRefetchInventory())
        dispatch(limitedReset({section: "cosmeticPayment"}))
    }

    const backToShop = () => {
        setWhichModalOpen("")
        navigate("/shop?tab=limited")
        dispatch(setRefetchInventory())
        dispatch(limitedReset({section: "cosmeticPayment"}))
    }

    return (
        <CosmeticModalTemplate
            show={show}
            isShowClose={true}
            title="Purchase successful!"
            name={currentModal.data.name}
            quantity={data?.quantitySuccess}
            rarity={currentModal.data.rarity}
            image={currentModal.data.image_url}
            closeClick={() => returnToInventory()}
            buttonOne={{text: "Back to shop", callBack: () => backToShop()}}
        />
    )
}

export default LimitedTimeCurrentCosmeticSuccessful